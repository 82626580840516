<template>
  <v-container>
    <v-row class="row">
      <v-col cols="6">
        <div>
          <h1 class="System_admin_text">WorkSheets</h1>
        </div>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>

    <v-row class="row">
      <!-- <v-col col="6" class="main_bar_text">WorkSheets</v-col>
          <v-col col="6" class="main_bar_text_left">WorkSheets</v-col> -->
    </v-row>

    <!--  <v-row class="row main_bar">
          <v-col col="6" class="text-css main_header_css">File name</v-col>
          <v-col col="6" class="text-css main_header_css_left"><span style="margin-left: -17px;">File name</span></v-col>
      </v-row> -->

    <v-row class="customer-search-wrap">
      <v-col cols="5" class="table_border_css">
        <router-link
          :to="`/BlindAdWorksheet/${this.currentDeal}`"
          class="text-color-css"
          ><span class="text-css">Blind Ad</span></router-link
        >
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="6" class="table_border_css_left">
        <router-link :to="`/CBR_1/${this.currentDeal}`" class="text-color-css"
          ><span class="text-color-css">CBR</span></router-link
        >
      </v-col>
    </v-row>

    <v-row class="customer-search-wrap">
      <v-col cols="5" class="table_border_css">
        <router-link
          :to="`/CashFlowCalculator/${this.currentDeal}`"
          class="text-color-css"
          ><span class="text-color-css">Cash Flow Calculator</span></router-link
        >
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="6" class="table_border_css_left">
        <router-link
          :to="`/MultipleCalculator/${this.currentDeal}`"
          class="text-css"
          ><span class="text-color-css"
            >Business Valuation Multiple</span
          ></router-link
        >
      </v-col>
    </v-row>

    <v-row class="customer-search-wrap">
      <v-col cols="5" class="table_border_css">
        <router-link :to="`/EngagementAgreement/${this.currentDeal}`" class="text-color-css"
          ><span class="text-color-css">Engagement Agreement</span></router-link
        >
      </v-col>
      <v-col cols="1"></v-col>
      <!-- <v-col cols="6" class="table_border_css_left">
        <span class="text-css not-link-css">Worksheet 6</span>
      </v-col> -->
    </v-row>

    <v-row class="customer-search-wrap">
      <!-- <v-col cols="5" class="table_border_css">
        <router-link :to="''" class="text-css not-link-css"
          ><span class="text-css not-link-css">Worksheet 7</span></router-link
        >
      </v-col> -->
      <v-col cols="1"></v-col>
      <!-- <v-col cols="6" class="table_border_css_left">
        <router-link :to="''" class="text-color-css"
          ><span class="text-css not-link-css">Worksheet 8</span></router-link
        >
      </v-col> -->
    </v-row>

    <v-row class="customer-search-wrap">
      <!-- <v-col cols="5" class="table_border_css">
        <router-link :to="''" class="text-color-css"
          ><span class="text-css not-link-css">Worksheet 9</span></router-link
        >
      </v-col> -->
      <v-col cols="1"></v-col>
      <!-- <v-col cols="6" class="table_border_css_left">
        <router-link :to="''" class="text-color-css"
          ><span class="text-css not-link-css">Worksheet 10</span></router-link
        >
      </v-col> -->
    </v-row>

    <v-row>
      <v-col cols="6"></v-col>
      <v-col cols="4"></v-col>
      <v-col cols="2">
        <div class="header-add-systemAdmin" style="float: right">
          <b-button class="back-button" @click="goBack">Back </b-button>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CommonServices from "../service/CommonServices";

export default {
  data() {
    return {
      headers: [
        { text: "Last Name", value: "LastName" },
        { text: "Address", value: "Address" },
        { text: "City", value: "City" },
        { text: "Zip", value: "Zip" },
        { text: "Email", value: "Email" },
        { text: "Phone", value: "Phone" },
      ],

      userActivityItem: {
        user_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "SystemAdmin",
        action: "",
        deal_id: "",
        json: "",
      },

      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      currentDeal: localStorage.getItem("DealID"),
    };
  },

  created() {
    this.checkPermissions();
    this.logActivity("opened");
  },

  methods: {
    checkPermissions() {
      if (this.role >3) this.$router.push("/");
    },

    goBack() {
      return this.$router.go(-1);
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.deal_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },
  },
};
</script>

<style type="text/css">
.System_admin_text {
  font-family: Poppins;
  font-style: normal;
  font-size: 25px;
  color: #000000;
  font-weight: 500;
}

.main_bar {
  background-color: #f3f3f3;
  border-radius: 4px;
  height: 60px;
}

.main_bar_text {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  padding-left: 8%;
}

.main_bar_text_left {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 23px;
  margin-left: -35px;
}

.table_border_css {
  padding-left: 8%;
  border: 1px solid rgba(196, 196, 196, 0.37);
  border-radius: 4px;
  height: 50px;
  cursor: pointer;
}

.table_border_css_left {
  padding-left: 3%;
  border: 1px solid rgba(196, 196, 196, 0.37);
  border-radius: 4px;
  height: 50px;
}

.text-css {
  cursor: pointer;
}

.table_border_css:hover {
  background-color: #f3f3f3 !important;
}

.table_border_css_left:hover {
  background-color: #f3f3f3 !important;
}

.main_header_css {
  padding-left: 8%;
}
.header-add-systemAdmin {
  max-width: 140px;
}
.header-add-systemAdmin a {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  background: #0157a0;
  box-shadow: 0px 4px 10px rgba(1, 87, 160, 0.25);
  color: #fff !important;
  padding: 12px 35px;
  border-radius: 60px;
}
.text-color-css {
  color: rgba(0, 0, 0, 0.87) !important;
}

.not-link-css {
  color: #5dade2;
}
</style>
